import { FramerNextPages } from '@graphcommerce/framer-next-pages'
import { GraphQLProvider } from '@graphcommerce/graphql'
import {
  CssAndFramerMotionProvider,
  DarkLightModeThemeProvider,
  PageLoadIndicator,
} from '@graphcommerce/next-ui'
import { CssBaseline } from '@mui/material'
import { AppProps } from 'next/app'
// eslint-disable-next-line import/order
import CompareMessageProvider from '../components/Product/AddToCompareBtn/CompareMessageProvider'
import CheckCart from '../components/checkCart'
import YekanBakhFaNumReg from '../components/font'
import { lightThemeLTR, lightThemeRTL } from '../components/theme'
import './global.css'

import {
  ModalContext,
  ModalContextValue,
  ScriptContext,
  ScriptContextValue,
} from '../context/context'
import { LoadingProvider } from '../context/loadingContext'
import WishListProvider from '../context/wishlistContext'
import { I18nProvider } from '../lib/i18n/I18nProvider'
import SearchSchema from '../components/schema/SearchSchema'
import { GlobalHead } from '../components/GlobalHead/GlobalHead'
import GoftinoWidget from '../components/GoftinoWidget'
import vazir from '../components/font'
import Head from 'next/head'

export default function ThemedApp(props: AppProps) {
  const { router } = props
  const { locale = 'fa' } = router
  return (
    <CssAndFramerMotionProvider>
      <I18nProvider key={locale} locale={locale}>
        <GraphQLProvider {...props}>
          <ModalContext.Provider value={ModalContextValue()}>
            <CompareMessageProvider>
              <WishListProvider>
                <LoadingProvider>
                  <ScriptContext.Provider value={ScriptContextValue()}>
                    <DarkLightModeThemeProvider
                      light={
                        locale.includes('fa') || locale.includes('shadia')
                          ? lightThemeRTL
                          : lightThemeLTR
                      }
                      dark={
                        locale.includes('fa') || locale.includes('shadia')
                          ? lightThemeRTL
                          : lightThemeLTR
                      }
                    >
                      <GoftinoWidget />
                      <div className={vazir.className}>
                        <SearchSchema />
                        <GlobalHead />
                        <CssBaseline />
                        <PageLoadIndicator />
                        <CheckCart>
                          <FramerNextPages {...props} />
                        </CheckCart>
                      </div>
                    </DarkLightModeThemeProvider>
                  </ScriptContext.Provider>
                </LoadingProvider>
              </WishListProvider>
            </CompareMessageProvider>
          </ModalContext.Provider>
        </GraphQLProvider>
      </I18nProvider>
    </CssAndFramerMotionProvider>
  )
}
