import { cn } from '../../lib/utils'
import LoadingIcon from '../icons/Loading'

type LoadingProps = {
  className?: string
}

export default function Loading({ className }: LoadingProps) {
  return (
    <div
      className={cn(
        'fixed top-0 bottom-0 right-0 left-0 bg-white/80 flex justify-center items-center z-[9999]',
        className,
      )}
    >
      <span>
        <LoadingIcon width={56} height={56} />
      </span>
    </div>
  )
}
